import React, { useState, useEffect } from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminPanel from './components/adminPanel.js';
import AdminLogin from './components/adminLogin.js';
import List from './components/adminPanel/list.js';
import Editor from './components/adminPanel/editor.js';
import DoubleInputForm from './components/adminPanel/doubleInputForm';

function App() {
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState({ auth: false, name: '' });
  const [websites, setWebsites] = useState([]);

  const handleUpdateAuth = (newState) => {
    setAuthenticated(newState);
    console.log(authenticated);
  };

  const handleUpdateWebsites = (newState) => {
    setWebsites(newState);
    console.log(websites);
  };

  useEffect(() => {
    const getWebsites = async () => {
      const websitesFromServer = await fetchWebsites();
      setWebsites(websitesFromServer);
    };

    getWebsites();
  }, []);

  const fetchWebsites = async () => {
    const res = await fetch('https://api.vbwebb.se/api/websites');
    const data = await res.json();
    return data;
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<AdminLogin setAuthenticated={handleUpdateAuth} />} />

          {websites.map((w) => (
            <Route
              key={w._id}
              exact
              path={`/${w.title}/admin-panel`}
              element={<AdminPanel info={w} authenticated={authenticated} />}
            >
              <Route
                path={`/${w.title}/admin-panel`}
                element={
                  <section className="p-16 lg:p-20">
                    <h2 className="text-2xl">{t('welcome')}</h2>
                    <p className="my-2">{t('service')}</p>
                  </section>
                }
              />

              {w.categories.map((c) => {
                switch (c.type) {
                  case 'Editor':
                    return(
                      <Route
                        key={c.name}
                        exact
                        path={`/${w.title}/admin-panel/${c.name}`}
                        element={<Editor initialContent={c.content} info={w} categoryInfo={c} item={null} websites={websites} setWebsites={handleUpdateWebsites}/>}
                      />
                    )
                  case 'List Editor':
                    return (
                      <Route
                        key={c.name}
                        exact
                        path={`/${w.title}/admin-panel/${c.name}`}
                        element={<List info={w} categoryInfo={c} websites={websites} setWebsites={handleUpdateWebsites} />}
                      />
                    );
                  case 'Double Input Form':
                    return (
                      <Route
                        key={c.name}
                        exact
                        path={`/${w.title}/admin-panel/${c.name}`}
                        element={<DoubleInputForm info={w} categoryInfo={c} websites={websites} setWebsites={handleUpdateWebsites} />}
                      />
                    );
                  default:
                    return null;
                }
              })}

              {w.categories.map((c) => {
                if (c.items) {
                  return c.items.map((i) => (
                    <Route
                      key={i.name}
                      exact
                      path={`/${w.title}/admin-panel/${c.name}/edit/${i.name}`}
                      element={<Editor initialContent={i.content} info={w} categoryInfo={c} item={i} websites={websites} setWebsites={handleUpdateWebsites} />}
                    />
                  ));
                }
                return null;
              })}
            </Route>
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;

