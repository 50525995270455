import React from "react";

import { useTranslation } from "react-i18next";

function Header() {
    const { t } = useTranslation();

    return (
        <footer className="flex justify-center items-center bg-gray-500 px-6 text-white h-[15vh] md:px-10 lg:px-14 xl:px-16">
            <div>
                <h3 className="text-lg font-bold">{t('customerService')}</h3>
                <a href='mailto:info@vbwebb.se'>info@vbwebb.se</a>
            </div>
        </footer>
    )
}


export default Header