import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

const RichTextEditor = ({ info, categoryInfo, item, initialContent, setWebsites, websites }) => {
  const {t} = useTranslation()
  
  const [content, setContent] = useState(initialContent || '');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const[imageData, setImageData] = useState({displayText: '', fileUrl: ''})
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const [linkData, setLinkData] = useState({ displayText: '', targetUrl: '' });
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [fileData, setFileData] = useState({ displayText: '', fileUrl: '' });
  const contentRef = useRef(null);

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current && initialContent) {
      contentRef.current.innerHTML = initialContent;
    }
  }, [initialContent]);

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    setIsBold(document.queryCommandState('bold'));
    setIsItalic(document.queryCommandState('italic'));
    setIsUnderline(document.queryCommandState('underline'));
  };

  const handleFontFamilyChange = (event) => {
    document.execCommand('fontName', false, event.target.value);
    contentRef.current.focus();
  };

  const handleFontSizeChange = (event) => {
    document.execCommand('fontSize', false, event.target.value);
    contentRef.current.focus();
  };

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
    contentRef.current.focus();
  };

  const handleUnderlineClick = () => {
    document.execCommand('underline', false, null);
    contentRef.current.focus();
  };

  const handleItalicClick = () => {
    document.execCommand('italic', false, null);
    contentRef.current.focus();
  };

  const handleLinkInsertion = () => {
    setIsLinkModalOpen(true);
  };

  const insertLinkAtCursorPosition = (linkData) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const linkNode = document.createElement('a');
    linkNode.href = linkData.targetUrl;
    linkNode.textContent = linkData.displayText;
    range.insertNode(linkNode);
  
    // Move the cursor to the end of the inserted link
    const newRange = document.createRange();
    newRange.setStartAfter(linkNode);
    newRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(newRange);
  };
  
  const handleInsertLink = () => {
    insertLinkAtCursorPosition(linkData);
    setIsLinkModalOpen(false);
    contentRef.current.focus();
  };
  

  const handleImageInsertion = () => {
    setIsImageModalOpen(true);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
  
    // Check the file size
    const maxFileSize = 0.5 * 1024 * 1024; // 5MB in this example
    if (file.size > maxFileSize) {
      window.alert('File size exceeds the allowed limit');
      setIsImageModalOpen(false);
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageData = e.target.result;
      setImageData({ displayText: file.name, imageUrl: imageData });
    };
    reader.readAsDataURL(file);
  };
  
  const insertImageAtCursorPosition = (imageData) => {
    const displayText = imageData.displayText;
    const imageUrl = imageData.imageUrl;
    const imageHtml = `<img class="inserted-image" src="${imageUrl}" alt="${displayText}">`;
  
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const imageNode = document.createElement('span');
    imageNode.innerHTML = imageHtml;
    range.insertNode(imageNode);
  
    // Move the cursor to the end of the inserted image
    const newRange = document.createRange();
    newRange.setStartAfter(imageNode);
    newRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(newRange);
  };
  
  const handleInsertImage = () => {
    insertImageAtCursorPosition(imageData);
    setIsImageModalOpen(false);
    contentRef.current.focus();
  };
  
  // Assuming you have a contentEditable element with a ref called `contentRef`
  

  const handleFileInsertion = () => {
    setIsFileModalOpen(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
  
    // Check the file size
    const maxFileSize = 0.5 * 1024 * 1024; // 5MB in this example
    if (file.size > maxFileSize) {
      window.alert('File size exceeds the allowed limit');
      setIsFileModalOpen(false);
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result;
      setFileData({ displayText: file.name, fileUrl: fileData });
    };
    reader.readAsDataURL(file);
  };
  
  const insertFileAtCursorPosition = (fileData) => {
    const displayText = fileData.displayText;
    const fileUrl = fileData.fileUrl;
    const fileHtml = `<a class="inserted-file" href="${fileUrl}" download="${displayText}">${displayText}</a>`;
  
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.deleteContents();
    const fileNode = document.createElement('span');
    fileNode.innerHTML = fileHtml;
    range.insertNode(fileNode);
  
    // Move the cursor to the end of the inserted file
    const newRange = document.createRange();
    newRange.setStartAfter(fileNode);
    newRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(newRange);
  };
  
  const handleInsertFile = () => {
    insertFileAtCursorPosition(fileData);
    setIsFileModalOpen(false);
    contentRef.current.focus();
  };
  
  // Assuming you have a contentEditable element with a ref called `contentRef`
  
  
  
  const handleSave = () => {
    const updatedContent = contentRef.current.innerHTML;
    const imageDataMap = {};
    const updatedContentWithImages = updatedContent.replace(
      /<img class="inserted-image" src="([^"]+)" alt="Image"\/>/g,
      (match, imageURL) => {
        const imageName = imageURL.split('/').pop();
        const imageData = imageDataMap[imageName];
        return `<img src="${imageData}" alt="Image" style="max-width: 25%;" />`;
      }
    );

    const updatedCategories = info.categories.map((category) => {
      if (category.name === categoryInfo.name) {
        if (category.items) {
          const updatedItems = category.items.map((i) => {
            if (item.name === i.name) {
              return { ...i, content: updatedContentWithImages };
            } else {
              return i;
            }
          });
          return { ...category, items: updatedItems };
        } else {
          category.content = updatedContentWithImages;
        }
      }
      return category;
    });

    const updatedWebsites = websites.map((w) => {
      if (w.title === info.title) {
        return { ...w, categories: updatedCategories };
      }
      return w;
    });

    setWebsites(updatedWebsites);

    fetch('https://api.vbwebb.se/api/websites/update-categories', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        title: info.title,
        categories: updatedCategories,
      }),
    })
      .then((res) => res.json())
      .then((data) => window.alert(data.msg));
  };

  return (
    <div className="mx-auto max-w-[80%]">
      <style>
        {`.inserted-link {
          color: blue;
        }

        .inserted-file {
          color: green;
        }`}
      </style>
      <div className="flex mb-4 p-2 rounded-b-sm bg-white shadow-lg">
        <select className="p-2 mr-2 shadow" onChange={handleFontFamilyChange}>
          <option value="Arial">Arial</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Verdana">Verdana</option>
        </select>
        <select className="p-2 mr-2 shadow" onChange={handleFontSizeChange}>
          <option value="1">12px</option>
          <option value="2">14px</option>
          <option value="3">16px</option>
          <option value="4">18px</option>
          <option value="5">20px</option>
          <option value="6">24px</option>
          <option value="7">32px</option>
          <option value="8">48px</option>
          <option value="9">72px</option>
        </select>
        <button
          className={`p-2 mr-2 shadow ${isBold ? 'font-bold' : ''}`}
          onClick={handleBoldClick}
        >
          {t('boldButtonText')}
        </button>
        <button
          className={`p-2 mr-2 shadow ${isUnderline ? 'underline' : ''}`}
          onClick={handleUnderlineClick}
        >
          {t('underlineButtonText')}
        </button>
        <button
          className={`p-2 mr-2 shadow ${isItalic ? 'italic' : ''}`}
          onClick={handleItalicClick}
        >
          {t('italicButtonText')}
        </button>
        <button className="p-2 mr-2 shadow" onClick={handleLinkInsertion}>
          {t('linkButtonText')}
        </button>
        <button className="p-2 mr-2 shadow" onClick={handleImageInsertion}>
        {t('imageButtonText')}
        </button>
        <button className="p-2 mr-2 shadow" onClick={handleFileInsertion}>
        {t('fileButtonText')}
        </button>
        <button className="p-2 shadow" onClick={handleSave}>
        {t('saveButtonText')}
        </button>
      </div>
      <div
        className="border bg-white rounded-sm shadow-lg p-2 outline-none mb-4"
        contentEditable="true"
        ref={contentRef}
        onInput={(event) => setContent(event.target.innerHTML)}
      />
      {isImageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 max-w-[80%] max-h-[80%] overflow-auto">
            <h2 className="text-lg font-bold mb-4">{t('insertImageModalTitle')}</h2>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <button className="mt-4 p-2 bg-blue-500 text-white" onClick={handleInsertImage}>
            {t('insertImageModalTitle')}
            </button>
            <button className="mt-4 p-2 bg-red-500 text-white" onClick={() => setIsImageModalOpen(false)}>
            {t('insertLinkCancelButton')}
            </button>
          </div>
        </div>
      )}
      {isLinkModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 max-w-[80%] max-h-[80%] overflow-auto">
            <h2 className="text-lg font-bold mb-4">{t('insertLinkModalTitle')}</h2>
            <div className="mb-2">
              <label htmlFor="link-display-text" className="block mb-1 font-semibold">
                {t('insertLinkDisplayTextInputLabelText')}
              </label>
              <input
                type="text"
                id="link-display-text"
                className="border px-2 py-1 w-full"
                value={linkData.displayText}
                onChange={(e) => setLinkData({ ...linkData, displayText: e.target.value })}
              />
            </div>
            <div>
              <label htmlFor="link-target-url" className="block mb-1 font-semibold">
                {t('insertLinkTargetUrlLabelText')}
              </label>
              <input
                type="url"
                id="link-target-url"
                className="border px-2 py-1 w-full"
                value={linkData.targetUrl}
                onChange={(e) => setLinkData({ ...linkData, targetUrl: e.target.value })}
              />
            </div>
            <button className="mt-4 p-2 bg-blue-500 text-white" onClick={handleInsertLink}>
              {t('insertLinkModalTitle')}
            </button>
            <button className="mt-4 p-2 bg-red-500 text-white" onClick={() => setIsLinkModalOpen(false)}>
            {t('insertLinkCancelButton')}
            </button>
          </div>
        </div>
      )}
      {isFileModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 max-w-[80%] max-h-[80%] overflow-auto">
            <h2 className="text-lg font-bold mb-4">{t('insertFileModalTitle')}</h2>
            <div className="mb-2">
              <label htmlFor="file-display-text" className="block mb-1 font-semibold">
                {t('insertLinkDisplayTextInputLabelText')}
              </label>
              <input
                type="text"
                id="file-display-text"
                className="border px-2 py-1 w-full"
                value={fileData.displayText}
                onChange={(e) => setFileData({ ...fileData, displayText: e.target.value })}
              />
            </div>
            <div>
              <label htmlFor="file-upload" className="block mb-1 font-semibold">
              {t('insertFileUploadLabelText')}
              </label>
              <input
                type="file"
                id="file-upload"
                className="border px-2 py-1 w-full"
                onChange={handleFileUpload}
              />
            </div>
            <button className="mt-4 p-2 bg-blue-500 text-white" onClick={handleInsertFile}>
            {t('insertFileModalTitle')}
            </button>
            <button className="mt-4 p-2 bg-red-500 text-white" onClick={() => setIsFileModalOpen(false)}>
              {t('insertLinkCancelButton')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RichTextEditor;
