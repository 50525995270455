import React from "react";

import { useTranslation } from "react-i18next";

function Header({title}) {
    const { t, i18n } = useTranslation();

    const handleTrans = () => {
        if(i18n.language === 'en'){
            i18n.changeLanguage('se');
        }else{
            i18n.changeLanguage('en')
        }
        
    };

    return (
        <header className="flex justify-between items-center bg-cyan-500 px-6 text-white h-[15vh] md:px-10 lg:px-14 xl:px-16">
            <button className="absolute right-2 top-2 hover:underline" onClick={() => handleTrans()}>
                EN/SE
            </button>

            <div>
                <h1 className="text-4xl">VBWEBB</h1>
                <p className="text-xl">CMS 1.0</p>
            </div>

            <h2 className="text-2xl">{title}</h2>
            <div className="w-[15%]"></div>
        </header>
    )
}


export default Header