import React, {useState} from "react";
import CreateNewBox from "./createNewBox";

import { useTranslation } from "react-i18next";

function DoubleInputForm({ info, categoryInfo, setWebsites, websites }) {
  const { t } = useTranslation();

  const [showCreateNewBox, setShowCreateNewBox] = useState(false);

  const toggleCreateNewBox = () => {
    setShowCreateNewBox(!showCreateNewBox);
  };

  const removeElem = (e) => {
    e.preventDefault();

    const row = e.target.parentNode.parentNode;
    const categories = info.categories;

    categories.forEach((c) => {
      if (c.name === categoryInfo.name) {
        c.items.forEach((item, index) => {
          if (item.name === row.firstChild.firstChild.value) {
            c.items.splice(index, 1);
          }
        });
      }
    });

    const updatedWebsites = websites.map((w) => {
      if(w.title === info.title){
        return {...w, categories: categories}
      }
      return w
    })
    
  setWebsites(updatedWebsites)

    fetch("https://api.vbwebb.se/api/websites/update-categories", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: info.title,
        categories: categories,
      }),
    })
      .then((res) => res.json())
  };

  const saveElem = (e) => {
    e.preventDefault();

    const row = e.target.parentNode.parentNode;
    const categories = info.categories;

    categories.forEach((c) => {
      if (c.name === categoryInfo.name) {
        c.items.forEach((item) => {
          if(row.id === item.id){
            item.name = row.firstChild.firstChild.value;
            item.input= row.firstChild.nextSibling.firstChild.value;
          }
        });
      }
    });

    const updatedWebsites = websites.map((w) => {
      if(w.title === info.title){
        return {...w, categories: categories}
      }
      return w
    })
    
  setWebsites(updatedWebsites)

    fetch("https://api.vbwebb.se/api/websites/update-categories", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: info.title,
        categories: categories,
      }),
    })
      .then((res) => res.json())
      .then((data) => window.alert(data.msg));
  };

  return (
    <section className="p-6 md:p-16 lg:p-20 w-full overflow-hidden flex flex-col items-center">
      <CreateNewBox showBox={showCreateNewBox} toggleCreateNewBox={toggleCreateNewBox} info={info} categoryInfo={categoryInfo} websites={websites} setWebsites={setWebsites} title={t('createNew')} />

      <h2 className="text-2xl">{categoryInfo.name}</h2>
      <p className="my-2">{categoryInfo.about}</p>
      <button className="font-normal text-base border border-cyan-500 rounded-md bg-cyan-500 text-white px-4 pb-2 pt-1 hover:bg-white hover:text-cyan-500 transition-colors" onClick={toggleCreateNewBox}>
      {t('createNew')}
      </button>

      <table className="mt-5 rounded-md bg-white shadow-lg whitespace-nowrap overflow-hidden">
        <thead className="bg-cyan-500 rounded-md">
          <tr>
            <th className="p-2">
              <h3 className="text-lg text-white">{t('name')}</h3>
            </th>
            <th>
                <h3 className="text-lg text-white">{categoryInfo.inputName}</h3>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {categoryInfo.items.map((i) => {
            return (
              <tr key={i.name} id={i.name}>
                <td className="p-2">
                  <input
                    className="w-full border border-gray-300 rounded-md py-1.5 px-4"
                    defaultValue={i.name}
                  />
                </td>
                <td className="p-2">
                  <input
                    className="w-full border border-gray-300 rounded-md py-1.5 px-4"
                    defaultValue={i.input}
                  />
                </td>
                <td className="p-2">
                  <button
                    onClick={saveElem}
                    className="font-normal text-base underline"
                  >
                    {t('save')}
                  </button>
                </td>
                <td className="p-2">
                  <button
                    onClick={removeElem}
                    className="text-red-500 underline"
                  >
                    {t('remove')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}

export default DoubleInputForm;
