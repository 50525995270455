import { Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Header from "./adminPanel/header.js";
import Footer from './adminPanel/footer.js';
import ToolList from "./adminPanel/toolList.js";


function AdminPanel({ info, authenticated }) {
  const { t } = useTranslation();


  if (authenticated.auth && info.name === authenticated.name) {
    return (
      <>
        <Header title={info.title} />
        <main className="bg-gray-200 min-h-[70vh] flex">
          <ToolList title={info.title} categories={info.categories} />
          <Outlet/>
        </main>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <h2 className="text-3xl font-bold p-10">{t('noAuth')}</h2>
      </>
    );
  }
}

export default AdminPanel;
