import React, {useState} from 'react';

import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

function NavMenu({title, categories}) {
    const { t } = useTranslation();

    const [isNavVisible, setNavVisible] = useState(false);
  
    const toggleNav = () => {
      setNavVisible((prevState) => !prevState);
    };
  
    return (
      <ul
        className={`flex flex-col bg-white h-fit-content list-none border-r border-solid border-gray-300 shadow-lg absolute h-[70vh] transform ${
          isNavVisible ? '-translate-x-[calc(100%-35.67px)]' : 'translate-x-0'
        } w-fit transition duration-500 ease-in-out`}
      >
        <li
          className="font-size-1em font-bold float-right py-4 px-14 border-b border-gray-300 border-solid cursor-pointer"
          onClick={toggleNav}
        >{t('menu')}
        <div className="absolute top-0 right-0 h-9 w-9 flex justify-center items-center">
          <span className="close-button w-4 h-[2px] bg-gray-500"></span>
        </div>
      </li>

      {categories.map(element => {
        return(
        <li key={element.name} className="font-size-1em float-right py-4 px-14 border-b border-gray-300 border-solid transition duration-500 ease-in-out">
          <Link to={`/${title}/admin-panel/${element.name}`}>{element.name}</Link>
        </li>)
      })}
      
      
    </ul>
  );
}

export default NavMenu;