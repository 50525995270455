import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";


function AdminLoginForm({setAuthenticated}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation if needed
    const payload = {
      username,
      password,
    };

    try {
      const response = await fetch(`https://api.vbwebb.se/api/websites/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.code === '00') {
        authenticated(data.website);
      } else {
        notAuthenticated(data.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const authenticated = (info) => {
    setAuthenticated({auth: true, name: info.name})
    navigate(`/${info.title}/admin-panel`)
  };

  const notAuthenticated = (msg) => {
    window.alert(msg);
    window.location.reload();
  };

  return (
    <div className="bg-gradient-to-r from-cyan-400 to-cyan-600 h-screen flex items-center justify-center">
      <form
        id="admin-login-form"
        className="flex flex-col items-center bg-white w-4/5 md:3/5 lg:w-2/5 xl:w-2/5 mx-auto p-8 rounded-lg shadow-lg"
        onSubmit={handleSubmit}
      >
        <h2 id="admin-login-title" className="text-2xl text-center mb-4">
          {t('adminPanel')}
        </h2>
        <h4 className="w-60 mx-auto mb-2">{t('use')}</h4>
        <p className="w-60 mx-auto mb-2">{t('username')}: admin</p>
        <p className="w-60 mx-auto mb-2">{t('password')} admin</p>
        <div className="w-60 mx-auto mb-4">
          <label htmlFor="username">{t('username')}</label>
          <div className="">
            <input
              className="admin-login-form-control text-sm w-full py-1 px-2 border-b-2 border-gray-400 focus:border-gray-700 transition duration-150 ease-in-out outline-none"
              name="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="w-60 mx-auto mb-8">
          <label htmlFor="password">{t('Password')}</label>
          <div className="">
            <input
              className="admin-login-form-control text-sm w-full py-1 px-2 border-b-2 border-gray-400 focus:border-gray-700 transition duration-150 ease-in-out outline-none"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <input
          id="admin-login-submit"
          className="w-60 px-4 py-1 bg-gray-800 text-white border border-gray-800 rounded-full transition duration-150 ease-in-out hover:bg-white hover:text-gray-800 focus:outline-none focus:bg-white focus:text-gray-800"
          type="submit"
          value="Log In"
        />
      </form>
    </div>
  );
}

export default AdminLoginForm;