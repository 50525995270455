import React, {useState} from 'react';

const CreateNewBox = ({ showBox, toggleCreateNewBox, info, categoryInfo, setWebsites, websites, title}) => {
    const [input, setInput] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const categories = info.categories;

    categories.forEach(c => {
        if(c.name === categoryInfo.name){
            c.items.push({
                name: input,
                link: replaceSpecialCharacters(input.toLowerCase().replace(/\s+/g, '-')),
                content: ''
            })
        }
        
    });

    function replaceSpecialCharacters(str) {
      const replacements = {
        'å': 'a',
        'ä': 'a',
        'ö': 'o'
      };
    
      return str.replace(/[åäö]/gi, (matched) => replacements[matched.toLowerCase()] || matched);
    }

    const updatedWebsites = websites.map((w) => {
        if(w.title === info.title){
          return {...w, categories: categories}
        }
        return w
      })
      
    setWebsites(updatedWebsites)

    fetch("https://api.vbwebb.se/api/websites/update-categories", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: info.title,
        categories: categories,
      }),
    })
      .then((res) => res.json())

    toggleCreateNewBox()
  };

  const handleClose = (event) => {
    event.preventDefault();
    toggleCreateNewBox();
  };

  return (
    <div className={`${showBox ? 'flex' : 'hidden'} justify-center items-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-screen h-screen`}>
      <form className="max-w-1/2 bg-white p-8 rounded-lg shadow relative" onSubmit={handleSubmit}>
        <button className="absolute top-1 right-1 bg-transparent rounded px-2 pb-1 transition-colors duration-200 hover:text-white hover:bg-cyan-500" onClick={handleClose}>
          x
        </button>
        <h3 className="text-2xl font-semibold mb-4">{title}</h3>
        <div className="flex flex-col mb-4">
          <label htmlFor="title" className="mb-2">Name</label>
          <input onChange={(e) => setInput(e.target.value)} type="text" name="title" className="p-2 border border-gray-300 rounded" />
        </div>
        <input type="submit" value="Create" className="font-normal text-base border border-cyan-500 rounded-md bg-cyan-500 text-white px-4 pb-2 pt-1 hover:bg-white hover:text-cyan-500 hover:cursor-pointer transition-colors" />
      </form>
    </div>
  );
};

export default CreateNewBox;

