import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    en: {
      translation: {
        welcome: "Welcome to your Admin Panel",
        service:
          "You're able to customize your website. If something is not working please contact customer support.",
        adminPanel: "Admin Panel",
        logIn: "Log in",
        username: "Username",
        password: "Password",
        use: "To use, try:",
        noAuth: "Not authenticated",
        menu: "Menu",
        createNew: "Create New",
        name: "Name",
        change: "Change",
        save: "Save",
        remove: "Remove",
        customerService: "Customer Service",
        boldButtonText: "Bold",
        underlineButtonText: "Underline",
        italicButtonText: "Italic",
        linkButtonText: "Link",
        imageButtonText: "Image",
        fileButtonText: "File",
        saveButtonText: "Save",
        insertImageModalTitle: "Insert Image",
        insertImageInputLabelText: "Upload Image:",
        insertImageCancelButton: "Cancel",
        insertLinkModalTitle: "Insert Link",
        insertLinkDisplayTextInputLabelText: "Display Text:",
        insertLinkTargetUrlLabelText: "Target URL:",
        insertLinkCancelButton: "Cancel",
        insertFileModalTitle: "Insert File",
        insertFileUploadLabelText: "File Upload:",
        insertFileButtonText: "Insert File",
        insertFileCancelButton: "Cancel",
      },
    },
    se: {
      translation: {
        welcome: "Välkommen till eran Administratör Panel",
        service:
          "Här kan du hantera innehållet på eran hemsida. Om något inte fungerar kontakta kundservice.",
        adminPanel: "Administratör Panel",
        logIn: "Logga in",
        username: "Användarnamn",
        password: "Lösenord",
        use: "För att använda, testa:",
        noAuth: "Du saknar tillgång",
        menu: "Meny",
        createNew: "Skapa ny",
        name: "Namn",
        change: "Ändra",
        save: "Spara",
        remove: "Ta bort",
        customerService: "Kundservice",
        boldButtonText: "Fet",
        underlineButtonText: "Understrykning",
        italicButtonText: "Kursiv",
        linkButtonText: "Länk",
        imageButtonText: "Bild",
        fileButtonText: "Fil",
        saveButtonText: "Spara",
        insertImageModalTitle: "Infoga bild",
        insertImageInputLabelText: "Ladda upp bild:",
        insertImageCancelButton: "Avbryt",
        insertLinkModalTitle: "Infoga länk",
        insertLinkDisplayTextInputLabelText: "Visningstext:",
        insertLinkTargetUrlLabelText: "Mål-URL:",
        insertLinkButtonText: "Infoga länk",
        insertLinkCancelButton: "Avbryt",
        insertFileModalTitle: "Infoga fil",
        insertFileDisplayTextInputLabelText: "Visningstext:",
        insertFileUploadLabelText: "Filuppladdning:",
        insertFileButtonText: "Infoga fil",
        insertFileCancelButton: "Avbryt",
      },
    },
  },
});

export default i18n;
